import React from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { AutocompleteOptions, getAlgoliaResults } from '@algolia/autocomplete-js';
import { AutocompleteState, BaseItem } from '@algolia/autocomplete-core';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import '@algolia/autocomplete-theme-classic';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchResultItem from '../SearchResultItem';
import { Autocomplete } from './Autocomplete';
import { InstantSearchNext } from 'react-instantsearch-nextjs';
import Image from 'next/image';

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  className?: string;
  key?: any;
  isMobile?: boolean;
};

const algoliaClient = algoliasearch('D5Z45XD2PC', 'c40f0689b79e0f0d20ac8c9103a72e7d');

export function AlgoliaAutocomplete({
  className,
  isMobile,
  ...autocompleteProps
}: AutocompleteProps) {
  const [search, setSearch] = React.useState(false);
  const autocompleteRef = React.useRef<any>(null);
  const { push } = useRouter();
  const pathname = usePathname();
  const searchClient: SearchClient = React.useMemo(
    () => ({
      ...algoliaClient,
      search(requests) {
        if (!search && !isMobile) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: ''
            }))
          });
        }
        return algoliaClient.search(requests);
      }
    }),
    [search, isMobile, autocompleteProps]
  );
  const handleSubmit = (args: any) => {
    autocompleteRef?.current?.setIsOpen(false);
    document.body.classList.remove('aa-Detached');
    setSearch(false);
    push(`/search?global[query]=${args.state.query}`);
  };

  React.useEffect(() => {
    if (search) {
      autocompleteRef?.current?.setIsOpen(true);
    }
  }, [search]);

  if (pathname === '/search') {
    // TODO: A better way to hide the search icon on the search page
    return null;
  }
  return (
    <>
      <IconButton
        sx={(theme) => ({
          'width': 58,
          'height': 58,
          [theme.breakpoints.down('md')]: {},

          '> svg': {
            width: 24,
            height: 24,
            objectFit: 'contain'
          }
        })}
        onClick={() => {
          setSearch(!search);
        }}>
        <Image
          alt="search-icon"
          src="/static/images/icons/search_icon.png"
          width={24}
          height={24}
        />
      </IconButton>
      <Box
        sx={{
          position: 'fixed',
          opacity: 0
        }}>
        <Autocomplete
          autocompleteRef={autocompleteRef}
          openOnFocus={search}
          onSubmit={handleSubmit}
          detachedMediaQuery=""
          components={{ SearchResultItem }}
          getSources={({ query = '' }: { query: string }) => [
            {
              sourceId: 'global',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'global',
                      query
                    }
                  ]
                });
              },
              templates: {
                item({ item, components }: any) {
                  return (
                    <components.SearchResultItem
                      key={item?.card?.id}
                      {...item?.card}
                      variant="searchResultItem"
                    />
                  );
                }
              }
            }
          ]}
        />
      </Box>
    </>
  );
}

const AlgoliaSearchBox = (props: any) => {
  return <AlgoliaAutocomplete {...props} />;
};

interface HitProps {
  hit: any;
}

export default AlgoliaSearchBox;
