import React from 'react';

import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import { default as MuiCardMedia } from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import sidekick from '@last-rev/contentful-sidekick-util';

import { getFirstOfArray } from '../utils/getFirstOfArray';
import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';
import type { CardProps, CardOwnerState } from '../Card/Card.types';
import { type LinkProps } from '../Link';

const Card = (props: CardProps) => {
  const {
    id,
    media,
    rteOverline: overline,
    rteTitle: title,
    rteSubtitle: subtitle,
    className,
    body,
    link,
    actions,
    variant,
    loading,
    sidekickLookup,
    sizes
  } = props;

  const ownerState = {
    ...props,
    variant
  };

  const image = getFirstOfArray(media);

  return (
    <ErrorBoundary>
      <Root
        ownerState={ownerState}
        data-testid="Card"
        {...sidekick(sidekickLookup)}
        className={className}
        elevation={0}
        sx={{ display: 'flex', position: 'relative' }}>
        {/* <CardWrap ownerState={ownerState}> */}
        {!!link ? (
          <CardLink
            component={CardActionArea}
            {...(link as any)}
            text=""
            variant="link"
            sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          />
        ) : null}

        {image || loading ? (
          // @ts-ignore: TODO
          <CardMedia ownerState={ownerState} sx={{}}>
            {!loading ? (
              <ContentModule
                sx={{
                  height: '100%',
                  width: 200,
                  objectFit: 'cover',
                  borderRadius: 1,
                  // aspectRatio: '4/3',
                  // width: 'auto',
                  maxWidth: 'unset'
                }}
                __typename="Media"
                {...sidekick(sidekickLookup, 'media')}
                {...image}
                data-testid="Card-media"
                sizes="200px"
              />
            ) : (
              <Skeleton variant="rectangular" />
            )}
          </CardMedia>
        ) : null}

        {!loading && (overline || title || subtitle || body) ? (
          // @ts-ignore: TODO
          <ContentWrap ownerState={ownerState} sx={{ pt: 1 }}>
            {!!overline && (
              <Overline
                {...sidekick(sidekickLookup, 'overline')}
                body={overline}
                __typename="RichText"
                data-testid="Card-overline"
                variant="overline"
                ownerState={ownerState}
              />
            )}

            {!!title && (
              <Title
                {...sidekick(sidekickLookup, 'title')}
                body={title}
                __typename="RichText"
                data-testid="Card-title"
                ownerState={ownerState}
                variant="h6"
              />
            )}

            {!!subtitle && (
              <Subtitle
                __typename="RichText"
                body={subtitle}
                {...sidekick(sidekickLookup, 'subtitle')}
                data-testid="Card-subtitle"
                ownerState={ownerState}
              />
            )}

            {body?.json ? (
              <BodyWrap ownerState={ownerState} {...sidekick(sidekickLookup, 'body')}>
                <Body
                  __typename="RichText"
                  body={body}
                  ownerState={ownerState}
                  data-testid="Card-body"
                  sx={{
                    // May be different on other variants
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis'
                  }}
                />
              </BodyWrap>
            ) : null}
          </ContentWrap>
        ) : null}
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(MuiCard, {
  name: 'SearchResultItem',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: CardOwnerState }>``;

const CardWrap = styled(MuiCard, {
  name: 'SearchResultItem',
  slot: 'CardWrap',
  overridesResolver: (props, styles) => [styles.cardWrap]
})<{ ownerState: CardOwnerState }>``;

const CardLink = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'CardLink',
  overridesResolver: (_, styles) => [styles.link]
})<{ ownerState: CardOwnerState }>``;

const CardMedia = styled(MuiCardMedia, {
  name: 'SearchResultItem',
  slot: 'CardMedia',
  overridesResolver: (_, styles) => [styles.cardMedia]
})<{ ownerState: CardOwnerState }>``;

const ActionsWrap = styled(CardActions, {
  name: 'SearchResultItem',
  slot: 'ActionsWrap',
  overridesResolver: (_, styles) => [styles.actionsWrap]
})<{ ownerState: CardOwnerState }>``;

const Action = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'CardAction',
  overridesResolver: (_, styles) => [styles.action]
})<{ ownerState: CardOwnerState }>``;

const ContentWrap = styled(CardContent, {
  name: 'SearchResultItem',
  slot: 'ContentWrap',
  overridesResolver: (_, styles) => [styles.contentWrap]
})<{ ownerState: CardOwnerState }>``;

const Overline = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'Overline',
  overridesResolver: (_, styles) => [styles.overline]
})<{ ownerState: CardOwnerState }>``;

const Title = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'Title',
  overridesResolver: (_, styles) => [styles.title]
})<{ ownerState: CardOwnerState }>``;

const Subtitle = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'Subtitle',
  overridesResolver: (_, styles) => [styles.subtitle]
})<{ ownerState: CardOwnerState }>``;
const BodyWrap = styled(Box, {
  name: 'SearchResultItem',
  slot: 'BodyWrap',
  overridesResolver: (_, styles) => [styles.bodyWrap]
})<{ ownerState: CardOwnerState }>``;

const Body = styled(ContentModule, {
  name: 'SearchResultItem',
  slot: 'Body',
  overridesResolver: (_, styles) => [styles.body]
})<{ ownerState: CardOwnerState }>``;

export default Card;
